import { FirebaseAnalytics } from "@capacitor-firebase/analytics";

import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import {
  FirebaseAuthentication,
  User,
} from "@capacitor-firebase/authentication";
import apiClient from "../apiClient/apiClient";
import { CreateNewUserVm, FirebaseAuthProvider } from "../apiClient/clients";

const firebaseConfig = {
  apiKey: "AIzaSyDIew2YP-sc3o6yO5M7ZGE9vhOcMvRBVy8",
  authDomain: "recipelog-92627.firebaseapp.com",
  projectId: "recipelog-92627",
  storageBucket: "recipelog-92627.appspot.com",
  messagingSenderId: "394680647974",
  appId: "1:394680647974:web:f92525732640aff26381e7",
  measurementId: "G-315PGHZJGQ",
};

//https://harryherskowitz.com/2021/08/23/firebase-capacitor.html
//https://github.com/firebase/firebase-js-sdk/issues/5019
//If we want to try logging in on the native layer:
//https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md
//Do we want to try logging the user in on the native layer as well to see if it persists???

// const app = initializeApp(firebaseConfig);
const app = !getApps()?.length ? initializeApp(firebaseConfig) : getApp();
//https://stackoverflow.com/questions/75241561/where-does-firebase-getapps-length-check-come-from
//https://github.com/vercel/next.js/discussions/11351#discussioncomment-17797
//https://github.com/vercel/next.js/discussions/11351

export const auth = getAuth(app);

// if (app?.name && typeof window !== "undefined") {
//   getAnalytics(app);
// }

// const googleProvider = new GoogleAuthProvider();

export const createNewUserAPI = async (
  user: User | null,
  firebaseProvider: FirebaseAuthProvider,
  firstName?: string,
  lastName?: string,
  displayName?: string
) => {
  if (!user?.email) {
    console.log("Email is null");
    return;
  }

  if (!user?.uid) {
    console.log("uid is null");
    return;
  }
  const newUser: CreateNewUserVm = {
    email: user.email,
    firstName: firstName,
    lastName: lastName,
    displayName: user.displayName as string | undefined,
    externalAuthProviderSub: user.uid,
    firebaseProvider,
  };
  try {
    //todo improve error handling and logging here
    return await apiClient.createUser(newUser);
  } catch (error) {
    console.error(error);
  }
};

export const firebaseLoginWithGoogle = async () => {
  try {
    const res = await FirebaseAuthentication.signInWithGoogle();
    const user = res.user;
    await firebaseLogEvent("sign_in", { method: "google" });
    return user;
    /**
     * WE'll be adding an isAuth0 flag = true to all current users (before the migration starts)
     * We'll also add a isMigratedFromAuth0ToFirebase = false
     * Also create a field for the method of login, e.g. username/google/apple
     * 1. Check if the user exists.
     * - IF the user exists. and the isAuth0 flag = true AND isMigratedFromAuth0ToFirebase = false.
     * - WE will update the ExternalAuthProviderSub to match googles userid
     * - We will then set isMigratedFromAuth0ToFirebase = true
     * - we will then set isAuth0 to be false.
     * 2. If the user exists, and isAuth0 = false. Ignore
     * 3. If the user doesn't exist. We will create them.
     *
     */
  } catch (err) {
    console.error(err);
    throw err;
    // alert(err.message);
  }
};

export const firebaseLoginWithApple = async () => {
  try {
    const res = await FirebaseAuthentication.signInWithApple();
    const user = res.user;
    await firebaseLogEvent("sign_in", { method: "apple" });
    return user;

    /**
     * WE'll be adding an isAuth0 flag = true to all current users (before the migration starts)
     * We'll also add a isMigratedFromAuth0ToFirebase = false
     * Also create a field for the method of login, e.g. username/google/apple
     * 1. Check if the user exists.
     * - IF the user exists. and the isAuth0 flag = true AND isMigratedFromAuth0ToFirebase = false.
     * - WE will update the ExternalAuthProviderSub to match googles userid
     * - We will then set isMigratedFromAuth0ToFirebase = true
     * - we will then set isAuth0 to be false.
     * 2. If the user exists, and isAuth0 = false. Ignore
     * 3. If the user doesn't exist. We will create them.
     *
     */
  } catch (err) {
    console.error(err);
    throw err;
    // alert(err.message);
  }
};

export const firebaseSendEmailVerification = async () => {
  try {
    await FirebaseAuthentication.sendEmailVerification();
  } catch (err) {
    console.error(err);
  }
};

export const firebaseSendPasswordResetEmail = async (email: string) => {
  try {
    await FirebaseAuthentication.sendPasswordResetEmail({
      email,
    });
  } catch (err) {
    console.error(err);
  }
};

export const firebaseLoginWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    await FirebaseAuthentication.signInWithEmailAndPassword({
      email,
      password,
    });
    await firebaseLogEvent("sign_in", { method: "email_password" });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const firebaseCreateUserWithEmailAndPassword = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => {
  try {
    const res = await FirebaseAuthentication.createUserWithEmailAndPassword({
      email,
      password,
    });
    const user = res.user;

    await firebaseLogEvent("sign_up", { method: "email_password" });
    return { user, firstName, lastName };
  } catch (err) {
    throw err;
    console.error(err);
  }
};

export const firebaseLogout = async () => {
  return await FirebaseAuthentication.signOut();
};

//Test  this forces a logout when the user resets password etc.
export const firebaseGetAccessToken = async () => {
  try {
    const { token } = await FirebaseAuthentication.getIdToken();
    if (token === null) {
      throw new Error("Failed to get ID token");
    }
    return token;
  } catch (error) {
    // console.error(error);
    await firebaseLogout();
  }
};

export const setFirebaseAnalyticsUserId = async (userId: string) => {
  try {
    await FirebaseAnalytics.setUserId({
      userId,
    });
  } catch (error) {
    console.error(`Error logging setUserId ${error}`);
  }
};

export const firebaseLogEvent = async (
  name: string,
  params: { [key: string]: any } = {}
): Promise<void> => {
  try {
    await FirebaseAnalytics.logEvent({ name, params });
    console.log(`successfully logged event ${name}`);
  } catch (error) {
    console.error(`Error logging event "${name}": ${error}`);
  }
};

export type ScreenView =
  | "Landing"
  | "ViewAllRecipes"
  | "ViewSingleRecipeAuthenticated"
  | "ViewSingleRecipeUnAuthenticated"
  | "ViewSingleRecipeCollectionRecipeAuthenticated"
  | "ViewSingleRecipeCollectionRecipeUnAuthenticated"
  | "CreateRecipe"
  | "ViewAllRecipeCollections"
  | "ViewSingleRecipeCollectionAuthenticated"
  | "ViewSingleRecipeCollectionUnauthenticated"
  | "EditRecipe"
  | "CreateRecipeCollection"
  | "CreateShoppingList"
  | "ViewAllShoppingLists"
  | "ViewSingleShoppingListAuthenticated"
  | "ViewSingleShoppingListUnauthenticated"
  | "EditShoppingList"
  | "SignUp"
  | "SignIn";

export const firebaseLogScreenView = async (
  screenName: ScreenView,
  screenClassOverride: string
) => {
  // await FirebaseAnalytics.setCurrentScreen({
  //   screenName,
  //   screenClassOverride,
  // });
};
