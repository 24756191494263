"use client";

import sortOptions from "./sortByOptions";

const getSortByOptionFromSessionStorage = (storageKey: string) => {
  if (typeof window !== "undefined") {
    const val = sessionStorage.getItem(storageKey);
    if (val) {
      return sortOptions.find((o) => o.value == val);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export default getSortByOptionFromSessionStorage;
