import { OptionType } from "../components/Forms/RecipeForm/RecipeIngredientForm";

const sortOptions: OptionType[] = [
  {
    value: "alphabetical",
    label: "Alphabetically",
  },
  {
    value: "recent",
    label: "Most Recent",
  },
];

export default sortOptions;
