import { appId, auth0Domain } from "@/auth.config";
import { App as CapApp } from "@capacitor/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isNative } from "../utils";

const useCapacitorAuth = (isIos = false) => {
  // Get the callback handler from the Auth0 React hook
  // const { handleRedirectCallback } = useAuthentication();
  const router = useRouter();
  const [slug, setSlug] = useState("");

  //TODO: Fix localhost here
  const callbackUri = isNative
    ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
    : "http://localhost:3000";

  useEffect(() => {
    if (isNative) {
      CapApp.addListener("appUrlOpen", async ({ url }) => {
        // if (url.startsWith(callbackUri)) {
        //   if (
        //     url.includes("state") &&
        //     (url.includes("code") || url.includes("error"))
        //   ) {
        //     await handleRedirectCallback(url);
        //   }

        //   await Browser.close();
        // }
        const slug = url.split(".app").pop();
        if (slug) {
          setSlug(slug);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isNative) {
      if (slug) {
        (async () => {
          await new Promise((r) => setTimeout(r, 500));
          await router.push(slug);
          setSlug("");
        })();
      }
    }
  }, [slug]);
};

export default useCapacitorAuth;
