export { default as getSortByOptionFromSessionStorage } from "./getSortByOptionFromSessionStorage";
export { default as handleApiLimitError } from "./handleApiLimitError";
export { default as imageSizeQueryParams } from "./imageSizeQueryParams";
export { default as isNative } from "./isNative";
export { default as preventEnterKeySubmission } from "./preventEnterKeySubmission";
export { default as setAxiosAuthToken } from "./setAxiosAuthToken";
export { default as sortOptions } from "./sortByOptions";
export { default as sortedRecipeCollectionItems } from "./sortRecipeCollectionItems";
export { default as sortShoppingListsByOwned } from "./sortShoppingListsByOwned";
export { default as writeToClipboard } from "./writeToClipboard";

export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  [key: string]: any; // To allow additional custom properties
}

export function isProblemDetailsErrorResponse(obj: any): obj is ProblemDetails {
  return "status" in obj && "title" in obj && "detail" in obj && "type" in obj;
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
