// import { User } from "@capacitor-firebase/authentication";
// import { onAuthStateChanged } from "firebase/auth";
// import React, { createContext, useContext, useEffect, useState } from "react";
// import { auth } from "../config/firebase";

// type AuthState = {
//   user: User | null;
//   isAuthenticated: boolean;
//   loading: boolean;
// };

// // Create a context
// const AuthContext = createContext<AuthState | undefined>(undefined);

// // Provider component that listens to auth state changes
// export function AuthProvider({ children }: { children: React.ReactNode }) {
//   const [authState, setAuthState] = useState<AuthState>({
//     user: null,
//     isAuthenticated: false,
//     loading: true, // Initialized as true
//   });

//   // useEffect(() => {
//   //   FirebaseAuthentication.addListener("authStateChange", async ({ user }) => {
//   //     setAuthState({
//   //       user,
//   //       isAuthenticated: user !== null, // You can adjust this based on your needs
//   //       loading: false, // Set to false once listener receives the first update
//   //     });
//   //     console.log(user);
//   //   });
//   // }, []);

//   useEffect(() => {
//     onAuthStateChanged(auth, async (user) => {
//       let firebaseUser: User | null = null;
//       if (user != null) {
//         firebaseUser = {
//           displayName: user.displayName,
//           email: user.email,
//           emailVerified: user.emailVerified,
//           isAnonymous: user.isAnonymous,
//           phoneNumber: user.phoneNumber,
//           photoUrl: user.photoURL,
//           providerId: user.providerId,
//           tenantId: user.tenantId,
//           uid: user.uid,
//         };
//       }
//       setAuthState({
//         user: firebaseUser,
//         isAuthenticated: user !== null, // You can adjust this based on your needs
//         loading: false, // Set to false once listener receives the first update
//       });
//       console.log(user);
//     });
//   }, []);

//   return (
//     <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
//   );
// }

// // Custom hook to use the auth state
// export function useAuthContext() {
//   const context = useContext(AuthContext);
//   if (context === undefined) {
//     throw new Error("useAuth must be used within a AuthProvider");
//   }
//   return context;
// }

import {
  FirebaseAuthentication,
  User,
} from "@capacitor-firebase/authentication";
import React, { createContext, useContext, useEffect, useState } from "react";
import { setFirebaseAnalyticsUserId } from "../config/firebase";
import { isNative } from "../utils";

type AuthState = {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
};

const AuthContext = createContext<AuthState | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    isAuthenticated: false,
    loading: true,
  });

  useEffect(() => {
    let timeoutId: any;
    //ATTEMPTING: To remove this at the start to see if it does anything....
    // FirebaseAuthentication.removeAllListeners();
    FirebaseAuthentication.addListener("authStateChange", async ({ user }) => {
      setAuthState({
        user,
        isAuthenticated: user !== null,
        loading: false,
      });
      if (user) {
        await setFirebaseAnalyticsUserId(user.uid);
      }
      // const setUserId = async () => {
      //   await FirebaseAnalytics.setUserId({
      //     userId: '123',
      //   });
      // };
      // clear the timeout when the auth state changes
      clearTimeout(timeoutId);
    });

    // set a timeout that expires after a certain period (e.g. 5 seconds)
    timeoutId = setTimeout(
      async () => {
        let user: User | null = null;
        try {
          const currentUser = await FirebaseAuthentication.getCurrentUser();
          user = currentUser.user;
        } catch (error) {
          setAuthState({
            user,
            isAuthenticated: user !== null,
            loading: false,
          });
        }
        setAuthState({
          user,
          isAuthenticated: user !== null,
          loading: false,
        });
      },
      isNative ? 2500 : 1000
    );

    return () => {
      clearTimeout(timeoutId);
      FirebaseAuthentication.removeAllListeners(); // cleanup all listeners
    }; // cleanup the timeout
  }, []);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
