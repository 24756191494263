import axios from "axios";
import logger from "./logger";

const setAxiosAuthToken = (token: string) => {
  if (!!token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    //Force a new sign-in?
    const msg =
      "User is unauthenticated. Clearing Token and forcing a new sign-in. ";
    console.error(msg);
    logger.error(msg);
    delete axios.defaults.headers.common["Authorization"];
    // signIn("azureb2c");
  }
};

export default setAxiosAuthToken;
