import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area";
import { useEffect, useState } from "react";

const useSafeAreaInsets = () => {
  const [insets, setInsets] = useState<SafeAreaInsets>();
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      setInsets({ insets });
    });
  }, []);
  return insets;
};

export default useSafeAreaInsets;
