/**
 * @summary Sort Recipe Collections by whether the is added to them or not. e.g. ones that are added go to the bottom.
 *
 */

import { RecipeCollectionVm, RecipeVm } from "../apiClient/clients";

const sortedRecipeCollectionItems = (
  recipe: RecipeVm,
  recipeCollectionData?: RecipeCollectionVm[]
) => {
  const collectionsWithAccess = recipeCollectionData?.filter(
    (r) =>
      r.currentUserAccessLevel?.toString() == "Owner" ||
      r.currentUserAccessLevel?.toString() == "Edit"
  );

  collectionsWithAccess?.sort((a, b) => {
    const aAlreadyAdded = !!a.recipes?.find((x) => x.recipeId === recipe.id);
    const bAlreadyAdded = !!b.recipes?.find((x) => x.recipeId === recipe.id);

    if (bAlreadyAdded && !aAlreadyAdded) {
      return -1;
    }
    if (aAlreadyAdded && !bAlreadyAdded) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });

  return collectionsWithAccess;
};

export default sortedRecipeCollectionItems;
