import { RecipeVm, ShoppingListVm } from "../apiClient/clients";

const sortShoppingListsByOwned = (
  shoppingListData?: ShoppingListVm[],
  recipe?: RecipeVm
) => {
  let data = shoppingListData?.sort((a, b) => {
    const aAlreadyAdded = !!a.recipes?.find((x) => x.id === recipe?.id);
    const bAlreadyAdded = !!b.recipes?.find((x) => x.id === recipe?.id);

    if (bAlreadyAdded && !aAlreadyAdded) {
      return -1;
    }
    if (aAlreadyAdded && !bAlreadyAdded) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });

  data = data?.sort((a, b) => Number(new Date() > new Date()));

  const sortedData = data?.sort((a, b) => {
    const dateA = new Date(Date.parse(a.dateCreated)).getTime();
    const dateB = new Date(Date.parse(b.dateCreated)).getTime();
    return dateB - dateA;
  });

  return sortedData;
};

export default sortShoppingListsByOwned;
