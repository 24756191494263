import toast from "react-hot-toast";
import isNative from "./isNative";

const writeToClipboard = (path: string) => {
  const url = isNative ? "https://www.recipelog.app" : window.location.origin;
  navigator.clipboard.writeText(`${url}/${path}`);
  toast.success("Copied to clipboard!");
};

export default writeToClipboard;
