// export const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
// export const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
export const domain = "recipelog.au.auth0.com";
export const clientId = "nKriDnyZymHnUnnLri8xyrQ7wODkIhso";
export const appId = "app.recipelog.www";

// This should reflect the URL added earlier to your "Allowed Logout URLs" setting
// in the Auth0 dashboard.
export const logoutUri =
  "app.recipelog.www://recipelog.au.auth0.com/capacitor/app.recipelog.www/callback";

export const redirectUriConfig =
  "app.recipelog.www://recipelog.au.auth0.com/capacitor/app.recipelog.www/callback";
// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
export const auth0Domain = domain;
