import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { iOSUrl } from "../../config/index";
import useGetAppVersion from "../../hooks/useGetAppVersion";

const AppUpgradeRequired = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isUpgradeRequired, isFetching } = useGetAppVersion();

  return (
    <Flex>
      <Modal isOpen={isUpgradeRequired} onClose={onClose}>
        <ModalOverlay backdropFilter="auto" backdropBlur="50px" />
        <ModalContent top={75} m="5%">
          <ModalHeader>
            <Text fontWeight={"bold"}>A new version is available</Text>
          </ModalHeader>
          <ModalBody>
            <Text>
              This update includes bug fixes, new features and performance
              improvements to make your experience better.
            </Text>
            <br />
            <Text>
              To continue, you will need to update your app to the latest
              version to keep your experience smooth and get the latest features
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              mr={3}
              onClick={() => {
                window.location.replace(iOSUrl);
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AppUpgradeRequired;
