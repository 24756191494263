import { SetStateAction } from "react";
import { ProblemDetails } from ".";
import { firebaseLogEvent } from "../config/firebase";

const handleApiLimitError = (
  err: ProblemDetails,
  setApiLimitError: (value: SetStateAction<string | undefined>) => void,
  onOpenUpgradeModal: () => void
) => {
  if (err?.type && (err?.type == "1001" || err?.type == "1002")) {
    setApiLimitError(err.detail);
    const limitType =
      err?.type == "1001" ? "free_api_limit_reached" : "paid_api_limit_reached";
    firebaseLogEvent(limitType, {
      title: err?.title,
      detail: err?.detail,
    });
    onOpenUpgradeModal();
  }
};

export default handleApiLimitError;
