const preventEnterKeySubmission = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const target = e.target as HTMLInputElement | HTMLTextAreaElement;
  if (e.key === "Enter" && !["TEXTAREA"].includes(target.tagName)) {
    e.preventDefault();
  }
};

export default preventEnterKeySubmission;
